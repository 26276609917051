import { Box } from '@mui/material'
import AboutStats from 'components/About/AboutStats'
import AboutUs from 'components/About/AboutUs'
import Hero from 'components/About/Hero'
import MileStone from 'components/About/MileStone'
import Mission from 'components/About/Mission'
import Vision from 'components/About/Vision'
import WhatWeDo from 'components/About/WhatWeDo'
import { useStateContext } from 'context/Context'
import { useEffect } from 'react'

const About = () => {
  const { setLoading } = useStateContext()
  useEffect(() => {
    setLoading(false)
  }, [setLoading])
  return (
    <Box>
      <Hero />
      <AboutUs />
      <AboutStats />
      <MileStone />
      <Mission />
      <Vision />
      <WhatWeDo />
    </Box>
  )
}

export default About
