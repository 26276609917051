import { Card, Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import background from 'assets/Home/testimonialsBG.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import logo1 from 'assets/Home/logo1.png'
import logo2 from 'assets/Home/logo2.png'
import logo3 from 'assets/Home/logo3.png'

const Testimonials = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(170deg, var(--secondary) 0%, var(--primary) 100%)',
          opacity: '0.95',
          borderRadius: 4,
        }}
      />
      <Container
        sx={{
          position: 'relative',
          py: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          data-aos="fade-up"
          data-aos-delay="100"
          align="center"
          color="text.secondary"
          variant="h4"
        >
          Our Clients
        </Typography>
        <Typography
          data-aos="fade-up"
          data-aos-delay="200"
          align="center"
          color="text.secondary"
          variant="h2"
          fontWeight="bold"
          gutterBottom
          sx={{
            mb: 3,
          }}
        >
          Our precious clients
        </Typography>

        <Swiper
          data-aos="fade-up"
          data-aos-delay="300"
          spaceBetween={24}
          grabCursor={true}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide>
            <Box
              sx={{
                py: 2,
                height: '100%',
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  height: '100%',

                  bgcolor: '#FFFFFF33',
                  boxShadow: '9px 11px 14px 0 rgba(0,0,0,.1)',
                  p: 2,
                  borderRadius: 3,
                  '& img': {
                    borderRadius: 3,
                  },
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={logo1} alt="logo1" width="100%" />
                </Box>
              </Card>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box
              sx={{
                py: 2,
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  height: '100%',

                  bgcolor: '#FFFFFF33',
                  boxShadow: '9px 11px 14px 0 rgba(0,0,0,.1)',
                  p: 2,
                  borderRadius: 3,
                  '& img': {
                    borderRadius: 3,
                  },
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={logo2} alt="logo2" width="100%" />
                </Box>
              </Card>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box
              sx={{
                py: 2,
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  height: '100%',

                  bgcolor: '#FFFFFF33',
                  boxShadow: '9px 11px 14px 0 rgba(0,0,0,.1)',
                  p: 2,
                  borderRadius: 3,
                  '& img': {
                    borderRadius: 3,
                  },
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={logo3} alt="logo3" width="100%" />
                </Box>
              </Card>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Container>
    </Box>
  )
}

export default Testimonials
