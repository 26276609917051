import { Box } from "@mui/material"
import AboutUs from "components/Home/AboutUs"
import Features from "components/Home/Features"
import Hero from "components/Home/Hero"
import OurPriority from "components/Home/OurPriority"
import Testimonials from "components/Home/Testimonials"

import hero from "assets/Home/hero3.png"
import accent from "assets/accent2.png"
import background from "assets/background2.jpg"
import Preloader from "components/Preloader"
import { useStateContext } from "context/Context"
import useImagePreloader from "hooks/usePreloader"
import { useEffect } from "react"

import aboutUs from "assets/Home/aboutUs2.jpg"
import aboutUsStats from "assets/Home/aboutUsStats.jpg"

const preloadSrcList = [hero, accent, background, aboutUs, aboutUsStats]

const Home = () => {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)

  const { setLoading } = useStateContext()
  useEffect(() => {
    console.log("Hero: imagesPreloaded", imagesPreloaded)
    if (imagesPreloaded) {
      setLoading(false)
    }
  }, [imagesPreloaded, setLoading])
  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          bgcolor: "primary.main",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          pointerEvents: imagesPreloaded ? "none" : "all",
          opacity: imagesPreloaded ? 0 : 1,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        <Preloader />
      </Box>
      <Hero heroImg={hero} accentImg={accent} backgroundImg={background} />
      <Features />
      <AboutUs aboutUsImg={aboutUs} accentImg={accent} />
      {/* <AboutStats aboutUsStatsImg={aboutUsStats} /> */}
      {/* <OurServices /> */}
      <OurPriority />
      <Testimonials />
    </Box>
  )
}

export default Home
