import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import TwitterIcon from "@mui/icons-material/Twitter"
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material"

import Navbar from "components/Navbar"
import { Link } from "react-router-dom"

const Hero = ({ heroImg, accentImg, backgroundImg }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "relative",
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          backgroundImage:
            "linear-gradient(170deg, var(--secondary) 0%, var(--primary) 100%)",
          opacity: "0.65",
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        }}
      />

      <Navbar />
      <Container
        sx={{
          position: "relative",
          transform: { xs: "translateY(0%)", sm: "translateY(60px)" },
          pt: { xs: 3, sm: 0 },
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
            sx={{
              p: { xs: 3, sm: 0 },
            }}
            item
            xs={12}
            sm={5}
          >
            <Box
              sx={{
                "& img": {
                  width: "100%",
                  height: "auto",
                  display: "block",
                  objectFit: "cover",
                  borderRadius: "8px",
                  WebkitMaskImage: `url(${accentImg})`,
                  WebkitMaskSize: "contain",
                  WebkitMaskPosition: "center center",
                  WebkitMaskRepeat: "no-repeat",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                alignItems="center"
                sx={{
                  flexWrap: "wrap-reverse",
                }}
              >
                <Grid item xs={12} sm={2}>
                  <Box
                    data-aos={isMobile ? "fade-up" : "fade-right"}
                    data-aos-delay="300"
                    sx={{
                      pt: { xs: 2, sm: 0 },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: { xs: "row", sm: "column" },
                      gap: 5,
                    }}
                  >
                    <FacebookIcon
                      sx={{
                        fontSize: 25,
                        color: "primary.main",
                      }}
                    />
                    <TwitterIcon
                      sx={{
                        fontSize: 25,
                        color: "primary.main",
                      }}
                    />
                    <InstagramIcon
                      sx={{
                        fontSize: 25,
                        color: "primary.main",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    "& img": {
                      width: { xs: "110%", sm: "130%" },
                      height: "auto",
                      display: "block",
                    },
                  }}
                >
                  <img
                    data-aos="fade-up"
                    data-aos-delay="300"
                    src={heroImg}
                    alt="hero"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              textAlign: { xs: "center", sm: "left" },
            }}
            data-aos={isMobile ? "fade-up" : "fade-left"}
            data-aos-delay="300"
          >
            <Typography variant="h4" color="text.primary" gutterBottom>
              5+ Years of Experience
            </Typography>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight="bold"
              gutterBottom
            >
              High Quality Ready Made Garments
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              fontWeight="bold"
              gutterBottom
            >
              RS Outfit (Pvt.) Ltd. is a renowned and trusted name in the
              garment industry, specializing in the production and distribution
              of high-quality, stylish clothing. With years of experience under
              our belt, we have built a strong reputation for delivering
              exceptional products with unparalleled customer service.
            </Typography>
            <br />
            <Button
              sx={{
                mb: { xs: 5, sm: 18 },
              }}
              component={Link}
              to="/about"
              size="large"
              variant="contained"
              color="primary"
            >
              <Typography variant="h6">Learn More</Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Hero
