import { Box, Container, Typography } from '@mui/material'
import background from 'assets/background.jpg'
import Navbar from 'components/Navbar'

const Hero = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(170deg, var(--secondary) 0%, var(--primary) 100%)',
          opacity: '0.95',
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        }}
      />

      <Navbar />
      <Container
        sx={{
          position: 'relative',
          textAlign: 'center',
          py: { xs: 3, sm: 5 },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          color="text.primary"
          gutterBottom
        >
          RS Outfit
        </Typography>
        <Typography
          variant="h2"
          fontWeight="bold"
          color="text.secondary"
          gutterBottom
        >
          Our Products
        </Typography>
      </Container>
    </Box>
  )
}

export default Hero
