import MenuIcon from "@mui/icons-material/Menu"
import { IconButton, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import rsLogoDrawer from "assets/rsLogoDrawer.png"
import { useState } from "react"
import { Link } from "react-router-dom"

const navItems = [
  { name: "Home", to: "/" },
  { name: "About", to: "/about" },
  // { name: 'Products', to: '/products' },
  { name: "Contact", to: "/contact" },
]

const SideBar = () => {
  const [open, setOpen] = useState(false)

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev)
  }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", width: 250, height: "100%" }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "1.5rem",
          my: 2,
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
          "& img": {
            height: "5rem",
          },
        }}
      >
        <img src={rsLogoDrawer} alt="rsLogoDrawer" />
      </Typography>

      <Divider />

      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              component={Link}
              to={item.to}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <div>
      <IconButton size="small" onClick={handleDrawerToggle}>
        <MenuIcon
          color="primary"
          sx={{
            fontSize: 30,
          }}
        />
      </IconButton>
      <SwipeableDrawer
        variant="temporary"
        open={open}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={() => handleDrawerToggle(false)}
        onOpen={() => handleDrawerToggle(true)}
      >
        {drawer}
      </SwipeableDrawer>
    </div>
  )
}
export default SideBar
