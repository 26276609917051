import { Box } from '@mui/material'
import ContactForm from 'components/Contact/ContactForm'
import ContactInfo from 'components/Contact/ContactInfo'
import Hero from 'components/Contact/Hero'
import { useStateContext } from 'context/Context'
import { useEffect } from 'react'

const Contact = () => {
    const { setLoading } = useStateContext()
    useEffect(() => {
      setLoading(false)
    }, [setLoading])
  return (
    <Box>
      <Hero />
      <ContactInfo />
      <ContactForm />
    </Box>
  )
}

export default Contact
