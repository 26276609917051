import { Box } from '@mui/material'
import Hero from 'components/Products/Hero'
import { useStateContext } from 'context/Context'
import { useEffect } from 'react'

const Products = () => {
  const { setLoading } = useStateContext()
  useEffect(() => {
    setLoading(false)
  }, [setLoading])
  return (
    <Box>
      <Hero />
    </Box>
  )
}

export default Products
