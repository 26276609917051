import Diversity3Icon from '@mui/icons-material/Diversity3'
import {
  Box,
  Container,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import aboutUsStats from 'assets/Home/aboutUsStats.jpg'

const AboutStats = () => {
  const theme = useTheme()
  return (
    <Container
      sx={{
        py: 8,
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${aboutUsStats})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
          position: 'relative',
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'text.secondary',
            opacity: '0.95',
            borderRadius: 3,
          }}
        />
        <Stack
          justifyContent="space-evenly"
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          divider={
            <Divider
              orientation={
                useMediaQuery(theme.breakpoints.down('sm'))
                  ? 'horizontal'
                  : 'vertical'
              }
              flexItem
            />
          }
          spacing={3}
          sx={{
            position: 'relative',
            px: { xs: 0, sm: 3 },
            py: { xs: 3, sm: 5 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Diversity3Icon />
            <Typography align="center" fontWeight="bold" variant="h3">
              100+
            </Typography>
            <Typography align="center" variant="subtitle1">
              Clients
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Diversity3Icon />
            <Typography align="center" fontWeight="bold" variant="h3">
              100+
            </Typography>
            <Typography align="center" variant="subtitle1">
              Clients
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',

              position: 'relative',
              color: 'text.secondary',
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                position: { xs: 'relative', sm: 'absolute' },
                bgcolor: 'primary.main',
                borderRadius: 2,
                width: { xs: '100%', sm: 'auto' },
                px: { xs: 0, sm: 10 },
                py: { xs: 3, sm: 7 },
              }}
            >
              <Diversity3Icon />
              <Typography align="center" fontWeight="bold" variant="h3">
                100+
              </Typography>
              <Typography align="center" variant="subtitle1">
                Clients
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Diversity3Icon />
            <Typography align="center" fontWeight="bold" variant="h3">
              100+
            </Typography>
            <Typography align="center" variant="subtitle1">
              Clients
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Container>
  )
}

export default AboutStats
