import { Container, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Mission = () => {
  return (
    <Container
      sx={{
        textAlign: 'center',
        py: { xs: 3, sm: 5 },
      }}
    >
      <Box
        sx={{
          bgcolor: 'secondary.main',
          p: { xs: 3, sm: 5 },
          borderRadius: 3,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            mb: { xs: 3, sm: 5 },
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
          }}
        >
          Mission
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
            mb: { xs: 3, sm: 5 },
          }}
        >
          <Typography variant="h6">
            Our mission is to empower individuals and businesses to express
            their unique sense of style through high-quality, custom-made
            garments, while prioritizing sustainability and environmental
            responsibility. From using ethically sourced fabrics and materials
            to minimizing waste and conserving energy, we strive to make
            environmentally conscious decisions at every step of our operations.
            Our goal is to create fashion that not only looks good but also does
            good for the planet.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
            '& > * + *': {
              mt: 1,
            },
          }}
        >
          <Typography variant="h6">
            Empower with high-quality custom garments.
          </Typography>
          <Divider />
          <Typography variant="h6"> Elevate brands & confidence.</Typography>
          <Divider />
          <Typography variant="h6">
            Exceed expectations in craftsmanship and innovation.
          </Typography>
          <Divider />
          <Typography variant="h6">
            Ensure sustainability & ethical sourcing.
          </Typography>
          <Divider />
          <Typography variant="h6">
            Inspire with memorable fashion statements.
          </Typography>
          <Divider />
          <Typography variant="h6">
            Create exceptional personalized garments.
          </Typography>
          <Divider />
          <Typography variant="h6"> Foster trust & mutual success.</Typography>
          <Divider />
          <Typography variant="h6">
            Make a positive impact through fashion excellence.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default Mission
