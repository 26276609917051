import { createContext, useContext, useState } from 'react'

export const Context = createContext()

export const ContextProvider = ({ children }) => {
  const [darkmode, setDarkmode] = useState(true)
  const [loading, setLoading] = useState(true)
  return (
    <Context.Provider
      value={{
        darkmode,
        setDarkmode,
        loading,
        setLoading,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useStateContext = () => useContext(Context)
