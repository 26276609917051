import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import cutting from 'assets/Home/cutting.png'
import finishing from 'assets/Home/finishing.png'
import knitting from 'assets/Home/knitting.png'
import printing from 'assets/Home/printing.png'
import sewing from 'assets/Home/sewing.png'
import shipping from 'assets/Home/shipping.png'
import { Link } from 'react-router-dom'

const FeatureCard = ({ img, title, desc }) => {
  return (
    <Box
      data-aos="fade-up"
      data-aos-delay="300"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Card
        sx={{
          borderRadius: 4,
          p: 1,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <CardMedia
          sx={{
            paddingTop: '75%',
            width: '100%',
            objectFit: 'cover',
            borderRadius: 3,
          }}
          image={img}
          title={title}
        />

        <Typography
          variant="h5"
          color="text.primary"
          fontWeight="bold"
          gutterBottom
          align="center"
          sx={{
            pt: 3,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          color="text.primary"
          gutterBottom
          align="center"
          sx={{
            opacity: 0.9,
          }}
        >
          {desc}
        </Typography>
        <Box
          sx={{
            height: '100%',
          }}
        />
        <Button
          component={Link}
          to="/about"
          sx={{
            mt: 1,
          }}
          endIcon={<ArrowForwardIcon />}
        >
          <Typography
            variant="subtitle1"
            color="text.primary"
            fontWeight="bold"
          >
            Learn More
          </Typography>
        </Button>
      </Card>
    </Box>
  )
}

const Features = () => {
  return (
    <Container
      sx={{
        my: { xs: 10, sm: 15 },
      }}
    >
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <FeatureCard
            img={knitting}
            title="Knitting & Dyeing"
            desc="We specialize in the art of knitting and dyeing, transforming raw materials into exquisite fabrics. Our expert team combines the latest technology with traditional techniques to create fabrics that are both visually stunning and comfortable to wear. With our careful dyeing process, we bring a spectrum of vibrant colors to life, ensuring that your garments make a statement."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            img={cutting}
            title="Cutting"
            desc="Precision is paramount in the world of custom clothing, which is why our cutting services are executed with meticulous attention to detail. Our skilled craftsmen utilize state-of-the-art cutting technology to shape fabrics according to your exact measurements and design specifications."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            img={printing}
            title="Printing"
            desc="Elevate your garments with our exceptional printing services. We offer a range of printing techniques, from screen printing to digital printing, allowing us to bring your designs to life with stunning clarity and vibrant colors. Whether it's custom patterns, logos, or graphics, our printing expertise will ensure that your garments command attention and express your individuality."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            img={sewing}
            title="Sewing"
            desc="Our skilled artisans bring their expertise and meticulous attention to detail to every stitch. Using the finest materials and state-of-the-art machinery, we carefully sew your garments, ensuring that each seam is flawless and every detail is perfect. Our commitment to excellence and precision results in garments that not only look exceptional but also provide unmatched comfort and durability."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            img={finishing}
            title="Finishing"
            desc="With our finishing services, we add those final touches that elevate your garments to new heights of quality and style. Our seasoned craftsmen meticulously inspect each garment, ensuring that all details, from buttons and zippers to hems and trims, are immaculate. Through our finishing touches, we take your custom clothing to its ultimate form, ready to be worn with confidence and pride."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            img={shipping}
            title="Shipping"
            desc="Delivering your garments safely and swiftly is our utmost priority. With our reliable shipping services, we ensure that your orders reach your doorstep promptly and securely, no matter where you are in the world. Our extensive network and careful handling guarantee that your custom clothing is received in perfect condition, ready to be enjoyed."
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Features
