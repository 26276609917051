import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const WhatWeDo = () => {
  return (
    <Container
      sx={{
        textAlign: 'center',
        pt: { xs: 3, sm: 5 },
      }}
    >
      <Box
        sx={{
          bgcolor: 'secondary.main',
          p: { xs: 3, sm: 5 },
          borderRadius: 3,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            mb: { xs: 3, sm: 5 },
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
          }}
        >
          What We Do
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
            mb: { xs: 3, sm: 5 },
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              mb: 1,
              p: 1,
              bgcolor: 'secondary.main',
              color: 'primary.main',
              borderRadius: 3,
            }}
          >
            Knitting & Dyeing
          </Typography>
          <Typography variant="h6">
            We specialize in the art of knitting and dyeing, transforming raw
            materials into exquisite fabrics. Our expert team combines the
            latest technology with traditional techniques to create fabrics that
            are both visually stunning and comfortable to wear. With our careful
            dyeing process, we bring a spectrum of vibrant colors to life,
            ensuring that your garments make a statement.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
            mb: { xs: 3, sm: 5 },
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              mb: 1,
              p: 1,
              bgcolor: 'secondary.main',
              color: 'primary.main',
              borderRadius: 3,
            }}
          >
            Cutting
          </Typography>
          <Typography variant="h6">
            Precision is paramount in the world of custom clothing, which is why
            our cutting services are executed with meticulous attention to
            detail. Our skilled craftsmen utilize state-of-the-art cutting
            technology to shape fabrics according to your exact measurements and
            design specifications.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
            mb: { xs: 3, sm: 5 },
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              mb: 1,
              p: 1,
              bgcolor: 'secondary.main',
              color: 'primary.main',
              borderRadius: 3,
            }}
          >
            Printing
          </Typography>
          <Typography variant="h6">
            Elevate your garments with our exceptional printing services. We
            offer a range of printing techniques, from screen printing to
            digital printing, allowing us to bring your designs to life with
            stunning clarity and vibrant colors. Whether it's custom patterns,
            logos, or graphics, our printing expertise will ensure that your
            garments command attention and express your individuality.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
            mb: { xs: 3, sm: 5 },
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              mb: 1,
              p: 1,
              bgcolor: 'secondary.main',
              color: 'primary.main',
              borderRadius: 3,
            }}
          >
            Sewing
          </Typography>
          <Typography variant="h6">
            Our skilled artisans bring their expertise and meticulous attention
            to detail to every stitch. Using the finest materials and
            state-of-the-art machinery, we carefully sew your garments, ensuring
            that each seam is flawless and every detail is perfect. Our
            commitment to excellence and precision results in garments that not
            only look exceptional but also provide unmatched comfort and
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
            mb: { xs: 3, sm: 5 },
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              mb: 1,
              p: 1,
              bgcolor: 'secondary.main',
              color: 'primary.main',
              borderRadius: 3,
            }}
          >
            Finishing
          </Typography>
          <Typography variant="h6">
            With our finishing services, we add those final touches that elevate
            your garments to new heights of quality and style. Our seasoned
            craftsmen meticulously inspect each garment, ensuring that all
            details, from buttons and zippers to hems and trims, are immaculate.
            Through our finishing touches, we take your custom clothing to its
            ultimate form, ready to be worn with confidence and pride.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'primary.main',
            color: 'secondary.main',
            borderRadius: 3,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              mb: 1,
              p: 1,
              bgcolor: 'secondary.main',
              color: 'primary.main',
              borderRadius: 3,
            }}
          >
            Shipping
          </Typography>
          <Typography variant="h6">
            Delivering your garments safely and swiftly is our utmost priority.
            With our reliable shipping services, we ensure that your orders
            reach your doorstep promptly and securely, no matter where you are
            in the world. Our extensive network and careful handling guarantee
            that your custom clothing is received in perfect condition, ready to
            be enjoyed.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default WhatWeDo
