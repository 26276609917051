import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'
import { Avatar, Box, Container, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import aboutUs from 'assets/Home/aboutUs2.jpg'
import accent from 'assets/accent2.png'

const AboutUs = () => {
  return (
    <Container
      sx={{
        py: 10,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        sx={{
          flexWrap: 'wrap-reverse',
        }}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            position: 'relative',
            '& img': {
              position: 'relative',
              width: '100%',
              height: 'auto',
              display: 'block',
              objectFit: 'cover',
              borderRadius: '8px',
              WebkitMaskImage: `url(${accent})`,
              WebkitMaskSize: 'contain',
              WebkitMaskPosition: 'center center',
              WebkitMaskRepeat: 'no-repeat',
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 'fit-content',
              height: 'fit-content',
              bgcolor: 'primary.main',
              borderRadius: 3,
              zIndex: 1,
              p: { xs: 4, sm: 2 },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              bottom: { xs: 20, sm: 10 },
              right: 20,
            }}
          >
            <Typography variant="h2" fontWeight="bold" color="text.secondary">
              5+
            </Typography>
            <Typography variant="h6" fontWeight="bold" color="text.secondary">
              Experience
            </Typography>
          </Box>
          <img src={aboutUs} alt="aboutUs" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            py: { xs: 3, sm: 0 },
          }}
        >
          <Typography
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
            }}
            variant="h5"
            color="text.primary"
            gutterBottom
          >
            About Us
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            color="text.primary"
            gutterBottom
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            Making Every Fabric Count
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.primary"
            gutterBottom
            sx={{
              opacity: 0.9,
              lineHeight: 1.5,
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            RS Outfit (Pvt.) Ltd. is a renowned and trusted name in the garment
            industry, specializing in the production and distribution of
            high-quality, stylish clothing. With years of experience under our
            belt, we have built a strong reputation for delivering exceptional
            products with unparalleled customer service.
            <br />
            We understand that fashion is a form of self-expression, and we
            believe in empowering our clients to create their own unique style.
            Our team of skilled designers and tailors work tirelessly to bring
            your vision to life, ensuring that every piece of clothing you
            receive is crafted with utmost precision and attention to detail.
            Experience the difference of working with a team that is dedicated
            to your success.
          </Typography>
          <Grid
            container
            spacing={5}
            sx={{
              my: 2,
            }}
            alignItems="stretch"
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  bgcolor: 'primary.main',
                  p: 3,
                  borderRadius: 3,
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: 'secondary.main',
                    width: 50,
                    height: 50,
                    borderRadius: 3,
                  }}
                  variant="rounded"
                >
                  <RemoveRedEyeOutlinedIcon
                    sx={{
                      fontSize: 30,
                      color: 'primary.main',
                    }}
                  />
                </Avatar>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color="text.secondary"
                  sx={{
                    my: 2,
                  }}
                >
                  Our Vision
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Becoming global leader in custom clothing, known for
                  innovation, sustainability, personalized fashion, trusted
                  partnership, and redefining boundaries.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  bgcolor: 'secondary.main',
                  p: 3,
                  borderRadius: 3,
                  color: 'primary.main',
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: 'primary.main',
                    width: 50,
                    height: 50,
                    borderRadius: 3,
                  }}
                  variant="rounded"
                >
                  <RocketLaunchOutlinedIcon
                    sx={{
                      fontSize: 30,
                      color: 'secondary.main',
                    }}
                  />
                </Avatar>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  // color="text.secondary"
                  sx={{
                    my: 2,
                  }}
                >
                  Our Mission
                </Typography>
                <Typography variant="subtitle1" color="text.primary">
                  Empowering through high-quality custom garments. Elevating
                  brands. Ensuring sustainability. Inspiring fashion statements.
                  Creating personalized excellence.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AboutUs
