import About from 'pages/About'
import Contact from 'pages/Contact'
import Home from 'pages/Home'
import Products from 'pages/Products'
import { Route, Routes } from 'react-router-dom'

const AppRoutes = () => {
  return (
    <main>
      <Routes>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="products" element={<Products />} />
      </Routes>
    </main>
  )
}

export default AppRoutes
