import { Container, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Vision = () => {
  return (
    <Container
      sx={{
        textAlign: 'center',
        py: { xs: 3, sm: 5 },
      }}
    >
      <Box
        sx={{
          bgcolor: 'primary.main',
          p: { xs: 3, sm: 5 },
          borderRadius: 3,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            mb: { xs: 3, sm: 5 },
            p: 1,
            bgcolor: 'secondary.main',
            color: 'primary.main',
            borderRadius: 3,
          }}
        >
          Vision
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'secondary.main',
            color: 'primary.main',
            borderRadius: 3,
            '& > * + *': {
              mt: 1,
            },
          }}
        >
          <Typography variant="h6">
            Global leader in custom clothing.
          </Typography>
          <Divider />
          <Typography variant="h6">innovation and craftsmanship.</Typography>
          <Divider />
          <Typography variant="h6">
            Leading sustainability in fashion.
          </Typography>
          <Divider />
          <Typography variant="h6">
            Inspiring personalized fashion empowerment.
          </Typography>
          <Divider />
          <Typography variant="h6">
            Trusted partner for fashion goals.
          </Typography>
          <Divider />
          <Typography variant="h6">
            Redefining boundaries, creating legacies.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default Vision
