import { Container, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const MileStone = () => {
  return (
    <Container
      sx={{
        textAlign: 'center',
        py: { xs: 3, sm: 5 },
      }}
    >
      <Box
        sx={{
          bgcolor: 'primary.main',
          p: { xs: 3, sm: 5 },
          borderRadius: 3,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            mb: { xs: 3, sm: 5 },
            p: 1,
            bgcolor: 'secondary.main',
            color: 'primary.main',
            borderRadius: 3,
          }}
        >
          Milestone
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            bgcolor: 'secondary.main',
            color: 'primary.main',
            borderRadius: 3,
            '& > * + *': {
              mt: 1,
            },
          }}
        >
          <Typography variant="h6">Building a Strong Team</Typography>
          <Divider />
          <Typography variant="h6">Expansion of Product Range</Typography>
          <Divider />
          <Typography variant="h6">Collaborations and Partnerships</Typography>
          <Divider />
          <Typography variant="h6">Customer Satisfaction</Typography>
          <Divider />
          <Typography variant="h6">Expanding Global Presence</Typography>
          <Divider />
          <Typography variant="h6">
            Social and Environmental Initiatives
          </Typography>
          <Divider />
          <Typography variant="h6">Sustainable Practices</Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default MileStone
