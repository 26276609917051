import { CssBaseline } from "@mui/material"
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles"
import AOS from "aos"
import "aos/dist/aos.css"
import Footer from "components/Footer"
import { useStateContext } from "context/Context"
import { useEffect } from "react"
import AppRoutes from "Routes"
import ScrollToTop from "utils/ScrollToTop"

const App = () => {
  const { loading } = useStateContext()
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#e683a8fe",
      },
      secondary: {
        main: "#dcd3e4",
      },

      text: {
        primary: "#4c4452",
        secondary: "#ffffff",
      },
      // background: {
      //   default: '#1d1b19',
      //   paper: '#181715',
      // },
    },
    typography: {
      fontFamily: ["Quicksand", "Poppins"].join(","),
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
  })

  useEffect(() => {
    if (!loading) {
      AOS.init({
        duration: 1000,
        once: true,
      })
    }
  }, [loading])

  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline />
      <ScrollToTop />
      <AppRoutes />
      <Footer />
    </ThemeProvider>
  )
}

export default App
