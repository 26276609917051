import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { Avatar, Box, Button, Container, Grid, Typography } from '@mui/material'

const ContactInfo = () => {
  return (
    <Container
      sx={{
        pt: 5,
      }}
    >
      <Grid container alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              py: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: 'primary.main',
                borderTopLeftRadius: 16,
                borderBottomLeftRadius: 16,
                borderTopRightRadius: { xs: 16, sm: 0 },
                borderBottomRightRadius: { xs: 16, sm: 0 },
                p: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                color: 'text.secondary',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'secondary.main',
                  width: 70,
                  height: 70,
                  borderRadius: 3,
                }}
                variant="rounded"
              >
                <SupportAgentIcon
                  sx={{
                    fontSize: 25,
                  }}
                />
              </Avatar>
              <Typography
                variant="h5"
                fontWeight="bold"
                gutterBottom
                align="center"
                sx={{
                  pt: 3,
                }}
              >
                +8801783853338
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
                align="center"
              >
                Shoilhat, Sreepur, Gazipur
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
                align="center"
              >
                info@rsoutfit.net
              </Typography>
              <Button
                color="secondary"
                sx={{
                  mt: 1,
                }}
                endIcon={<ArrowForwardIcon />}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Learn More
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              height: '100%',
              bgcolor: 'secondary.main',
              borderRadius: 3,
              p: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              color: 'text.primary',
            }}
          >
            <Avatar
              sx={{
                bgcolor: 'primary.main',
                width: 70,
                height: 70,
                borderRadius: 3,
              }}
              variant="rounded"
            >
              <SupportAgentIcon
                sx={{
                  fontSize: 25,
                }}
              />
            </Avatar>
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              align="center"
              sx={{
                pt: 3,
              }}
            >
              +8801783853338
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              align="center"
            >
              Shoilhat, Sreepur, Gazipur
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              align="center"
            >
              info@rsoutfit.net
            </Typography>
            <Button
              color="primary"
              sx={{
                mt: 1,
              }}
              endIcon={<ArrowForwardIcon />}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Learn More
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              py: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: 'primary.main',
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
                borderTopLeftRadius: { xs: 16, sm: 0 },
                borderBottomLeftRadius: { xs: 16, sm: 0 },
                p: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                color: 'text.secondary',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'secondary.main',
                  width: 70,
                  height: 70,
                  borderRadius: 3,
                }}
                variant="rounded"
              >
                <SupportAgentIcon
                  sx={{
                    fontSize: 25,
                  }}
                />
              </Avatar>
              <Typography
                variant="h5"
                fontWeight="bold"
                gutterBottom
                align="center"
                sx={{
                  pt: 3,
                }}
              >
                +8801783853338
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
                align="center"
              >
                Shoilhat, Sreepur, Gazipur
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
                align="center"
              >
                info@rsoutfit.net
              </Typography>
              <Button
                color="secondary"
                sx={{
                  mt: 1,
                }}
                endIcon={<ArrowForwardIcon />}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Learn More
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContactInfo
