import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Box, Button, Container, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'

const ContactForm = () => {
  return (
    <Container
      sx={{
        pt: 7,
      }}
    >
      <Typography align="center" variant="h3" fontWeight="bold">
        Get In Touch
      </Typography>
      <br />
      <Typography align="center" variant="subtitle1">
        Get in touch with us for any query
      </Typography>
      <br />
      <br />
      <TextField
        InputProps={{
          sx: {
            borderRadius: 3,
          },
        }}
        InputLabelProps={{
          sx: {
            color: 'text.primary',
          },
        }}
        fullWidth
        label="Email"
        variant="outlined"
      />
      <br />
      <br />
      <TextField
        InputProps={{
          sx: {
            borderRadius: 3,
          },
        }}
        InputLabelProps={{
          sx: {
            color: 'text.primary',
          },
        }}
        fullWidth
        label="Phone"
        variant="outlined"
      />
      <br />
      <br />
      <TextField
        InputProps={{
          sx: {
            borderRadius: 3,
          },
        }}
        InputLabelProps={{
          sx: {
            color: 'text.primary',
          },
        }}
        fullWidth
        label="Name"
        variant="outlined"
      />
      <br />
      <br />
      <TextField
        InputProps={{
          sx: {
            borderRadius: 3,
          },
        }}
        InputLabelProps={{
          sx: {
            color: 'text.primary',
          },
        }}
        multiline
        rows={4}
        fullWidth
        label="Message"
        variant="outlined"
      />
      <br />
      <br />
      <Button
        sx={{
          py: 1.2,
        }}
        fullWidth
        variant="contained"
        color="primary"
      >
        <Typography align="center" variant="subtitle1">
          Submit
        </Typography>
      </Button>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          mt: 2,
        }}
      >
        <Button
          sx={{
            p: 1.5,
          }}
          variant="contained"
          color="secondary"
        >
          <FacebookIcon color="primary" />
        </Button>

        <Button
          sx={{
            p: 1.5,
          }}
          variant="contained"
          color="secondary"
        >
          <InstagramIcon color="primary" />
        </Button>
        <Button
          sx={{
            p: 1.5,
          }}
          variant="contained"
          color="secondary"
        >
          <TwitterIcon color="primary" />
        </Button>
      </Box>
    </Container>
  )
}

export default ContactForm
