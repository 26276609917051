import { Container, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import one from 'assets/Home/pri1.png'
import two from 'assets/Home/pri2.png'
import three from 'assets/Home/pri3.png'
import four from 'assets/Home/pri4.png'
import five from 'assets/Home/pri5.png'

const OurPriority = () => {
  return (
    <Box>
      <Container
        sx={{
          pt: 10,
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
          }}
          data-aos="fade-up"
          data-aos-delay="100"
          variant="h4"
          color="text.primary"
          fontWeight="bold"
          gutterBottom
        >
          Our Priority
        </Typography>
        <Typography
          data-aos="fade-up"
          data-aos-delay="100"
          sx={{
            textAlign: 'center',
            mb: 5,
          }}
          variant="h2"
          color="text.primary"
          fontWeight="bold"
          gutterBottom
        >
          Quality is Our Priority
        </Typography>

        <Grid
          sx={{
            mb: 5,
          }}
          container
          spacing={3}
          alignItems="stretch"
        >
          <Grid item xs={12} sm={4}>
            <Box
              data-aos="fade-up"
              data-aos-delay="100"
              sx={{
                paddingTop: '120%',
                backgroundImage: `url(${one})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              data-aos="fade-up"
              data-aos-delay="200"
              sx={{
                paddingTop: '120%',
                backgroundImage: `url(${two})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              data-aos="fade-up"
              data-aos-delay="300"
              sx={{
                paddingTop: '120%',
                backgroundImage: `url(${three})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              data-aos="zoom-in"
              data-aos-delay="300"
              sx={{
                paddingTop: '70%',
                backgroundImage: `url(${four})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              data-aos="zoom-in"
              data-aos-delay="300"
              sx={{
                paddingTop: '70%',
                backgroundImage: `url(${five})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 3,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default OurPriority
