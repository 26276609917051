import { Box, Button, Hidden, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import rslogo from 'assets/rslogo.png'
import { Link, useLocation } from 'react-router-dom'
import SideBar from './SideBar'

const Navbar = () => {
  const location = useLocation()

  return (
    <AppBar
      position="relative"
      sx={{
        bgcolor: 'transparent',
      }}
      elevation={0}
    >
      <Container>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          disableGutters
        >
          <Button
            sx={{
              p: 0,
            }}
            variant="text"
            component={Link}
            to="/"
            color="primary"
          >
            <img src={rslogo} alt="rslogo" width={250} />
          </Button>
          <Hidden smDown>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 5,
              }}
            >
              <Button
                variant={location.pathname === '/' ? 'outlined' : 'text'}
                component={Link}
                to="/"
                color="primary"
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Home
                </Typography>
              </Button>
              <Button
                variant={location.pathname === '/about' ? 'outlined' : 'text'}
                component={Link}
                to="/about"
                color="primary"
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  About
                </Typography>
              </Button>
              {/* <Button
                variant={
                  location.pathname === '/products' ? 'outlined' : 'text'
                }
                component={Link}
                to="/products"
                color="primary"
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Products
                </Typography>
              </Button> */}
              <Button
                variant={location.pathname === '/contact' ? 'outlined' : 'text'}
                component={Link}
                to="/contact"
                color="primary"
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Contact
                </Typography>
              </Button>
            </Box>
          </Hidden>
          <Hidden smUp>
            <SideBar />
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Navbar
