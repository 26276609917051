import CallIcon from '@mui/icons-material/Call'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Button, Container, Divider, Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import rslogo from 'assets/rslogo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <Box>
      <Container>
        <Box sx={{ transform: 'translateY(50px)' }}>
          <Box
            sx={{
              bgcolor: 'secondary.main',
              p: 5,
              borderRadius: 3,
              mt: 5,
            }}
            data-aos="flip-up"
            data-aos-delay="100"
          >
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography color="primary" variant="h4" fontWeight="bold">
                  Our Newsletters
                </Typography>
                <Typography color="primary" variant="subtitle1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputProps={{
                    sx: {
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'primary.main',
                    },
                  }}
                  fullWidth
                  label="Your Email"
                  variant="outlined"
                />
                <Button
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    py: 1,
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          py: 3,
          bgcolor: 'primary.main',
          borderTopLeftRadius: { xs: 12, sm: 16 },
          borderTopRightRadius: { xs: 12, sm: 16 },
        }}
      >
        <Container
          sx={{
            mt: '50px',
          }}
        >
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} sm={4}>
              <Button
                sx={{
                  p: 0,
                }}
                variant="text"
                component={Link}
                to="/"
                color="primary"
              >
                <img src={rslogo} alt="rslogo" width={250} />
              </Button>

              <Typography
                variant="subtitle1"
                color="secondary"
                fontWeight="bold"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1,
                  mt: 2,
                }}
              >
                <Button
                  sx={{
                    p: 1.5,
                  }}
                  variant="contained"
                  color="secondary"
                >
                  <FacebookIcon color="primary" />
                </Button>

                <Button
                  sx={{
                    p: 1.5,
                  }}
                  variant="contained"
                  color="secondary"
                >
                  <InstagramIcon color="primary" />
                </Button>
                <Button
                  sx={{
                    p: 1.5,
                  }}
                  variant="contained"
                  color="secondary"
                >
                  <TwitterIcon color="primary" />
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'flex-start', sm: 'center' },
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Typography variant="h5" color="secondary" fontWeight="bold">
                  Navigation
                </Typography>
                <Typography
                  component={Link}
                  to="/"
                  variant="subtitle1"
                  color="secondary"
                  fontWeight="bold"
                >
                  Home
                </Typography>
                <Typography
                  component={Link}
                  to="/about"
                  variant="subtitle1"
                  color="secondary"
                  fontWeight="bold"
                >
                  About Us
                </Typography>
                {/* <Typography
                  component={Link}
                  to="/products"
                  variant="subtitle1"
                  color="secondary"
                  fontWeight="bold"
                >
                  Products
                </Typography> */}
                <Typography
                  component={Link}
                  to="/contact"
                  variant="subtitle1"
                  color="secondary"
                  fontWeight="bold"
                >
                  Contact
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'flex-start', sm: 'center' },
                alignItems: 'flex-end',
              }}
            >
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: { xs: 'flex-start', sm: 'flex-end' },
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Typography variant="h5" color="secondary" fontWeight="bold">
                  Work Hours
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontWeight="bold"
                >
                  7 AM - 5 PM, Mon - Sat
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontWeight="bold"
                  sx={{
                    textAlign: { xs: 'left', sm: 'right' },
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>

                <Button
                  sx={{
                    mt: 2,
                  }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<CallIcon color="primary" />}
                >
                  Call Us
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider
            sx={{
              borderRadius: 1,
              height: 1.2,
              bgcolor: '#FFFFFF33',
              my: 3,
            }}
          />
          <Typography
            align="center"
            color="text.secondary"
            sx={{ typography: { xs: 'subtitle2', sm: 'h6' } }}
          >
            Copyright © {new Date().getFullYear()}. All rights reserved by
            RSOutfit.
          </Typography>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
